import * as React from "react";
import { useEffect } from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar/Sidebar";
import inflateRoutes from "../routes";
import { IRouteDefinition } from "../typings";
import {useState} from "react";

const Admin = (props: any) => {
  const { firebaseSvc } = props;
  const [toLogin, setToLogin] = useState<boolean>(false);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
  });

  useEffect(() => {
    firebaseSvc.userState.subscribe((user: any) => {
      if (!user) {
        setToLogin(true);
      }
    });
  });

  const routes = inflateRoutes({ firebaseSvc });

  const getRoutes = (routes: {
    map: (arg0: (prop: any, key: any) => JSX.Element | null) => {};
  }) => {
    return routes.map((route: IRouteDefinition, index: number) => {
      if (route.layout === "/admin") {
        return (
          <Route
            path={route.layout + route.path}
            render={() => route.component}
            key={index}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path: any) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  if (toLogin) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("assets/img/brand/intivinelogo_words.jpg"),
          imgAlt: "..."
        }}
        firebaseSvc={firebaseSvc}
      />
      <div className="main-content">
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
          firebaseSvc={firebaseSvc}
        />
        <Switch>{getRoutes(routes)}</Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
