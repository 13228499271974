// TODO: Should come from environment variables
export default {
    firebaseConfig: {
        apiKey: "AIzaSyCilAOfjEB3QDFMn2LYuP3EQkZrYU6deps",
        authDomain: "the-shine-scale.firebaseapp.com",
        databaseURL: "https://the-shine-scale.firebaseio.com/",
        projectId: "the-shine-scale",
        storageBucket: "the-shine-scale.appspot.com",
        messagingSenderId: "the-shine-scale",
    },
}
