import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
// @ts-ignore
import heartImage from "../../images/heart-icon.png";
// @ts-ignore
import mindImage from "../../images/mind-icon.png";
// @ts-ignore
import shineImage from "../../images/shine-icon.png";
import { averages } from "../../utils/scoring.util";

const Header = (props:any) => {
  const scoreAverages = props.users ? averages(props.users) : null;
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Quizzes
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {scoreAverages && scoreAverages.totalQuizzes}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Heart Avg.
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{scoreAverages && Math.round(scoreAverages.hearth)}</span>
                      </div>
                      <Col className="col-auto">
                        <div>
                          <img src={heartImage} style={{width: '45px'}}/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Mind Avg.
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{scoreAverages && Math.round(scoreAverages.mind)}</span>
                      </div>
                      <Col className="col-auto">
                        <div>
                          <img src={mindImage} style={{width: '45px'}}/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Shine Avg.
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{scoreAverages && Math.round(scoreAverages.shineScale)}</span>
                      </div>
                      <Col className="col-auto">
                        <div>
                          <img src={shineImage} style={{width: '45px'}}/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
