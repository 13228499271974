import * as React from "react";
import Index from "./views/Index";
import Profile from "./views/examples/Profile";
import Register from "./views/examples/Register";
import Login from "./views/examples/Login";
import Tables from "./views/examples/Tables";

const inflateRoutes = (props: any) => {
  return [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2 text-primary",
      component: <Index {...props} />,
      layout: "/admin"
    },
    // {
    //   path: "/user-profile",
    //   name: "User Profile",
    //   icon: "ni ni-single-02 text-yellow",
    //   component: <Profile {...props} />,
    //   layout: "/admin"
    // },
    // {
    //   path: "/tables",
    //   name: "Tables",
    //   icon: "ni ni-bullet-list-67 text-red",
    //   component: <Tables {...props} />,
    //   layout: "/admin"
    // },
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: <Login {...props} />,
      layout: "/auth"
    },
    // {
    //   path: "/register",
    //   name: "Register",
    //   icon: "ni ni-circle-08 text-pink",
    //   component: <Register {...props} />,
    //   layout: "/auth"
    // }
  ]
};

export default inflateRoutes;
