export const answerKey = [
    {
        group: 'c',
        key: 'c1A',
        weight: 2
    },
    {
        group: 'c',
        key: 'c2A',
        weight: 1
    },
    {
        group: 'd',
        key: 'd1B',
        weight: 1
    },
    {
        group: 'd',
        key: 'd2A',
        weight: 2
    },
    {
        group: 'e',
        key: 'e1A',
        weight: 1
    },
    {
        group: 'e',
        key: 'e2A',
        weight: 2
    },
    {
        group: 'f',
        key: 'f1B',
        weight: 1
    },
    {
        group: 'f',
        key: 'f2B',
        weight: 2
    },
    {
        group: 'g',
        key: 'g1A',
        weight: 1
    },
    {
        group: 'g',
        key: 'g2A',
        weight: 2
    },
    {
        group: 'h',
        key: 'h1A',
        weight: 1
    },
    {
        group: 'h',
        key: 'h2B',
        weight: 2
    },
    {
        group: 'i',
        key: 'i1A',
        weight: 1
    },
    {
        group: 'i',
        key: 'i2B',
        weight: 2
    },
    {
        group: 'j',
        key: 'j1A',
        weight: 1
    },
    {
        group: 'j',
        key: 'j2A',
        weight: 2
    },
    {
        group: 'k',
        key: 'k1B',
        weight: 1
    },
    {
        group: 'k',
        key: 'k2A',
        weight: 2
    },
    {
        group: 'l',
        key: 'l1B',
        weight: 2
    },
    {
        group: 'l',
        key: 'l2A',
        weight: 1
    },
    {
        group: 'm',
        key: 'm1B',
        weight: 1
    },
    {
        group: 'm',
        key: 'm2A',
        weight: 2
    },
    {
        group: 'n',
        key: 'n1A',
        weight: 2
    },
    {
        group: 'n',
        key: 'n2B',
        weight: 1
    },
    {
        group: 'o',
        key: 'o1A',
        weight: 2
    },
    {
        group: 'o',
        key: 'o2A',
        weight: 1
    },
    {
        group: 'p',
        key: 'p1B',
        weight: 1
    },
    {
        group: 'p',
        key: 'p2A',
        weight: 2
    },
]
