import { answerKey } from "./answerKey.util";

export const scoring = (record: any) => {
  const recordArray = record ? Object.values(record[0]) : [];
  const scores: any = [
    {
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0
    },
    {
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0
    }
  ];

  for (let i = 0; i < answerKey.length; i++) {
    if (i < 14) {
      if (recordArray[i] === answerKey[i].key) {
        scores[0][answerKey[i].group] += answerKey[i].weight;
      }
    } else {
      if (recordArray[i] === answerKey[i].key) {
        scores[1][answerKey[i].group] += answerKey[i].weight;
      }
    }
  }
  // console.log(scores);
  // scores[0] = scoreAsPercentage(scores[0]);
  // scores[1] = scoreAsPercentage(scores[1]);
  return scores;
};

export const averages = (users: any) => {
  if (users.length > 0) {
    let scores = {
      hearth: 0,
      mind: 0,
      shineScale: 0,
      totalQuizzes: 0
    };
    for (let i = 0; i < users.length; i++) {
      users[i].score ? (scores.hearth += users[i].score.hearth) : null;
      users[i].score ? (scores.mind += users[i].score.mind) : null;
      users[i].score ? (scores.shineScale += users[i].score.shineScale) : null;
      users[i].score ? (scores.totalQuizzes ++) : null;
    }
    scores.hearth = scores.hearth / scores.totalQuizzes;
    scores.mind = scores.mind / scores.totalQuizzes;
    scores.shineScale = scores.shineScale / scores.totalQuizzes;
    return scores;
  }
};
