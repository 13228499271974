/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import * as React from "react";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { FirebaseService } from "../../services/firebase.service";
import { IUserCredentials } from "../../typings";

const Login = ({ firebaseSvc }: { firebaseSvc: FirebaseService }) => {
  const [toDashboard, setToDashboard] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<IUserCredentials>({
    username: "",
    password: "",
    method: "Email&Password"
  });

  useEffect(() => {
    firebaseSvc.userState.subscribe(user => {
      if (user) {
        setToDashboard(true);
      }
    });
  });

  const handleValueChange = (name: "username" | "password") => (event: any) => {
    setCredentials({ ...credentials, [name]: event.target.value });
  };

  const handleLogin = async () => {
    try {
      await firebaseSvc.singIn(credentials);
    } catch (err) {
      console.error(err);
    }
  };

  if (toDashboard) {
    return <Redirect to="/" />;
  }
  const { username, password } = credentials;
  return (
    <React.Fragment>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    value={username}
                    onChange={handleValueChange("username")}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={handleValueChange("password")}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleLogin}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default Login;
