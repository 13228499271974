import * as firebase from "firebase/app";
import env from "../environment/env";
import { IClient, IUser, IUserCredentials } from "../typings";
import { BehaviorSubject, Observable } from "rxjs";

const firebaseConfig = env.firebaseConfig;

// Firebase
require("firebase/auth");
require("firebase/database");

export class FirebaseService {
  private readonly _firebaseApp?: firebase.app.App;
  private readonly fbUserState = new BehaviorSubject<any | Error>("Initial");

  constructor() {
    if (this._firebaseApp) {
      return;
    }
    this._firebaseApp = firebase.initializeApp(firebaseConfig);
    this._firebaseApp.auth().onAuthStateChanged(this.fbUserState);
  }

  get db() {
    if (this._firebaseApp) {
      return this._firebaseApp.database();
    }
  }

  get userState(): Observable<any> {
    return this.fbUserState;
  }

  async getSingleRecord(key:any): Promise<IClient[]> {
    if(!this.db) {
      throw new Error("No Database Connected")
    }

    try {
      const snapshot = await this.db.ref(`/users/${key}`).once("value")

      if (!snapshot) {
        return [];
      }
      return Object.values(snapshot.val()); // TODO: what are we doing here also
    } catch (err) {
      throw err;
    }
  }

  async getClients(): Promise<IClient[]> {
    if (!this.db) {
      throw new Error("No Database connected");
    }

    try {
      const snapshot = await this.db.ref("/users").once("value");

      if (!snapshot) {
        return [];
      }
      return Object.values(snapshot.val()); // TODO: what are we doing here D:
    } catch (err) {
      throw err;
    }
  }

  async singIn(userCredentials: IUserCredentials) {
    if (!this._firebaseApp) {
      throw new Error("FirebaseService.login: need a firebase instance");
    }

    const { method, username, password, credential } = userCredentials;

    switch (method) {
      case "Facebook":
      case "Gmail":
      case "Email&Password":
        if (!username || !password) {
          throw new Error(
            "FirebaseService.login: missing username or password"
          );
        }
      default:
        return this._firebaseApp
          .auth()
          .signInWithEmailAndPassword(username, password);
    }
  }

  async signOut() {
    if (!this._firebaseApp) {
      throw new Error("FirebaseService.login: need a firebase instance");
    }

    return this._firebaseApp.auth().signOut();
  }

  healthLog(componentName: string = "") {
    console.log(`FirebaseService : healthLog : ${componentName}`, {
      instance: this._firebaseApp
    });
  }
}
