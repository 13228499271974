import React, { useEffect, useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import { scoring } from "../../utils/scoring.util";
import { HorizontalBar } from "react-chartjs-2";
import { mind, heart } from "../../variables/charts";

const RecordModal = (props: any) => {

  const updateChart = () => {
    const scores = scoring(props.singleRecord);
    mind.data.datasets[0].data.length = 0;
    mind.data.datasets[0].data = Object.values(scores[0]);
    heart.data.datasets[0].data.length = 0;
    heart.data.datasets[0].data = Object.values(scores[1])
  }

  updateChart();

  return (
    <Modal isOpen={props.modalOpen} centered style={{ height: "300px"}}>
      <ModalBody>
        <h1 onClick={props.modalCloseHandler} style={{ cursor: "pointer" }}>
          X
        </h1>
        <div className="row mx-auto">
          <label className="w-50">
            Name:
            {props.singleRecord && (
              <p>
                {props.singleRecord[3]} {props.singleRecord[6]}
              </p>
            )}
          </label>
          <label className="w-50">
            Email:
            {props.singleRecord && <p>{props.singleRecord[2]}</p>}
          </label>
        </div>
        <div className="row justify-content-between mx-auto">
          <label className="w-30">
            <b>Shine:</b>
            {props.singleRecord && (
                <p className="font-weight-bold">{props.singleRecord[8] ? Math.round(props.singleRecord[8].shineScale) : "N/A"}</p>
            )}
          </label>
          <label className="w-30">
            Company:
            {props.singleRecord && (
              <p>{props.singleRecord[1] ? props.singleRecord[1] : "N/A"}</p>
            )}
          </label>
          <label className="w-30">
            Industry:
            {props.singleRecord && (
              <p>{props.singleRecord[4] ? props.singleRecord[4] : "N/A"}</p>
            )}
          </label>
        </div>
        <div className="col">
          <h6 className="text-uppercase text-muted ls-1 mb-1">The Shine Scale Results</h6>
          {props.singleRecord && (
            <h2 className="mb-0">
              Heart -{" "}
              {props.singleRecord[8]
                ? Math.round(props.singleRecord[8].hearth)
                : "N/A"}{" "}
            </h2>
          )}
        </div>
        {/* Chart */}
        <div>
          <HorizontalBar data={heart.data} options={heart.options} />
        </div>
        <div className="col">
          {props.singleRecord && (
            <h2 className="mb-0">
              Mind -{" "}
              {props.singleRecord[8]
                ? Math.round(props.singleRecord[8].mind)
                : "N/A"}{" "}
            </h2>
          )}
        </div>
        {/* Chart */}
        <div>
          <HorizontalBar data={mind.data} options={mind.options} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RecordModal;
