import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Row, Col, Input } from "reactstrap";

import Header from "../components/Headers/Header";
import { IClient } from "../typings";
import RecordModal from "../components/RecordModal/RecordModal";

const Index = ({firebaseSvc}: any) => {
  const [activeNav, setActiveNav] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("")
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [users, setUsers] = useState<IClient[] | any>();
  const [retrievedUsers, setRetrievedUsers] = useState(false);
  const [singleRecord, setSingleRecord] = useState(undefined);

  useEffect(() => {
    firebaseSvc.getClients().then((users: any) => {
      setUsers(users.reverse());
      setRetrievedUsers(true);
    });
  }, [retrievedUsers]);

  const toggleNavs = (e: any, index: any) => {
    e.preventDefault();
    setActiveNav(index);
    chartExample1Data === "data1"
      ? setChartExample1Data("data2")
      : setChartExample1Data("data1");
    let wow = () => {};
    // @ts-ignore   
    wow.bind(this);
    setTimeout(() => wow(), 1000);
  };

  const getSingleRecordHandler = (key:any) => {
    firebaseSvc.getSingleRecord(key).then((record: any) => {
      if(record[6]==key){
        record[6]=record[7]        
        record[8]=record[9]
      }
      setSingleRecord(record)
    })
    setModalOpen(true)
  }

  const setFilterHandler = (e:any) => {
    const value = e.target.value
    setFilterValue(value)
  }

  const modalCloseHandler = () => {
    setModalOpen(false)
  }
  return (
    <React.Fragment>
      <Header users={users} />
      <RecordModal modalOpen={modalOpen} modalCloseHandler={modalCloseHandler} singleRecord={singleRecord}/>
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Quiz Results</h3>
                  </div>
                  <div>
                    <Input placeholder={"Search by Company"} onChange={e => setFilterHandler(e)}/>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Heart</th>
                    <th scope="col">Mind</th>
                    <th scope="col">Shine</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.filter((item:any) => item.company && item.company.toLowerCase().includes(filterValue.toLowerCase())).map((user: any) => (
                      <tr key={user.key} onClick={() => getSingleRecordHandler(user.key)} style={{cursor: 'pointer'}}>
                        <th scope="row">
                          {user.firstName} {user.lastName}
                        </th>
                        <td>{user.email}</td>
                        <td>
                          {user.score ? Math.round(user.score.hearth) : ""}
                        </td>
                        <td>{user.score ? Math.round(user.score.mind) : ""}</td>
                        <td>
                          {user.score ? Math.round(user.score.shineScale) : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Index;
