const Chart = require("chart.js");

// Chart extension for making the bars rounded
// Code from: https://codepen.io/jedtrow/full/ygRYgo

Chart.elements.Rectangle.prototype.draw = function() {
  var ctx = this._chart.ctx;
  var vm = this._view;
  var left, right, top, bottom, signX, signY, borderSkipped, radius;
  var borderWidth = vm.borderWidth;
  // Set Radius Here
  // If radius is large enough to cause drawing errors a max radius is imposed
  var cornerRadius = 6;
  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || "bottom";
  } else {
    // horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    borderSkipped = vm.borderSkipped || "left";
  }
  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    var halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero).
    var borderLeft = left + (borderSkipped !== "left" ? halfStroke * signX : 0);
    var borderRight =
      right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
    var borderTop = top + (borderSkipped !== "top" ? halfStroke * signY : 0);
    var borderBottom =
      bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }
  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;
  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  var corners = [[left, bottom], [left, top], [right, top], [right, bottom]];
  // Find first (starting) corner with fallback to 'bottom'
  var borders = ["bottom", "left", "top", "right"];
  var startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }
  function cornerAt(index: number) {
    return corners[(startCorner + index) % 4];
  }
  // Draw rectangle from 'startCorner'
  var corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);
  for (var i = 1; i < 4; i++) {
    corner = cornerAt(i);
    let nextCornerId = i + 1;
    if (nextCornerId === 4) {
      nextCornerId = 0;
    }
    // let nextCorner = cornerAt(nextCornerId);
    let width = corners[2][0] - corners[1][0];
    let height = corners[0][1] - corners[1][1];
    let x = corners[1][0];
    let y = corners[1][1];
    // eslint-disable-next-line
    var radius: any = cornerRadius;
    // Fix radius being too large
    if (radius > height / 2) {
      radius = height / 2;
    }
    if (radius > width / 2) {
      radius = width / 2;
    }
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
  }
  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};

Chart.defaults.global.legend.display = false;

export let heart = {
  options: {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          if (tooltipItem.xLabel === 0) {
            label += 'Opportunity for Improvement';
          } else if (tooltipItem.xLabel === 1) {
            label += 'Some Sparks';
          } else if (tooltipItem.xLabel === 2) {
            label += 'Sparks Often';
          } else if (tooltipItem.xLabel === 3) {
            label += 'You are Sparking!';
          }
          return label;
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function(value: any, index: any, values: any) {
              if (value === 0) {
                return "Opportunity";
              } else if (value === 1) {
                return "Some";
              } else if (value === 2) {
                return "Often";
              } else {
                return "Sparking!";
              }
            },
            beginAtZero: true,
            min: 0,
            max: 3,
            steps: 2,
            stepSize: 1
          }
        }
      ]
    }
  },
  data: {
    labels: [
      "Energy",
      "Passion",
      "Creativity",
      "Drive",
      "Motivating",
      "Presence",
      "Compassion"
    ],
    datasets: [
      {
        fill: true,
        backgroundColor: "#fbf5ad",
        data: [0, 0, 0, 0, 0, 0, 0]
      }
    ]
  }
};

export let mind = {
  options: {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          if (tooltipItem.xLabel === 0) {
            label += 'Opportunity for Improvement';
          } else if (tooltipItem.xLabel === 1) {
            label += 'Some Sparks';
          } else if (tooltipItem.xLabel === 2) {
            label += 'Sparks Often';
          } else if (tooltipItem.xLabel === 3) {
            label += 'You are Sparking!';
          }
          return label;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 10,
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            callback: function(value: any, index: any, values: any) {
              if (value === 0) {
                return "Opportunity";
              } else if (value === 1) {
                return "Some";
              } else if (value === 2) {
                return "Often";
              } else {
                return "Sparking!";
              }
            },
            beginAtZero: true,
            min: 0,
            max: 3,
            steps: 2,
            stepSize: 1
          }
        }
      ]
    }
  },
  data: {
    labels: [
      "Influence",
      "Wit",
      "Active Listener",
      "Solution Driven",
      "Inquisitiveness",
      "Conscientious",
      "Control"
    ],
    datasets: [
      {
        barThickness: 1,
        fill: true,
        backgroundColor: "#fbf5ad",
        data: [0, 0, 0, 0, 0, 0, 0]
      }
    ]
  }
};
